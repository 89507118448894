import PropTypes from 'prop-types'; // material
import { Stack } from '@mui/material';

// ----------------------------------------------------------------------

TextIconLabel.propTypes = {
  icon: PropTypes.any,
  value: PropTypes.any,
  endIcon: PropTypes.bool,
  sx: PropTypes.any
};

export default function TextIconLabel({ icon, value, endIcon = false, sx, ...other }) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        typography: 'body2',
        ...sx
      }}
      {...other}
    >
      {!endIcon && icon}
      {value}
      {endIcon && icon}
    </Stack>
  );
}
