import jwtDecode from 'jwt-decode';
import { createContext, useEffect, useReducer } from 'react';

import PropTypes from 'prop-types';
// utils
import axios from 'utils/axios';
import { isValidToken, setSession } from 'utils/jwt';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: false,
      user
    };
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: jwtDecode(accessToken)
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  // const login = async (email, password) => {
  //   try {
  //     const response = await axios.post('/api/auth/login', {
  //       email,
  //       password
  //     });
  //     const {
  //       data: { token },
  //       message
  //     } = response.data;
  //     setSession(token);
  //     dispatch({
  //       type: 'LOGIN',
  //       payload: {
  //         user: jwtDecode(token)
  //       }
  //     });
  //     return message;
  //   } catch (error) {
  //     // console.log(error);
  //     return
  //   }
  // };

  const login = async (email, password) =>
    new Promise((resolve, reject) => {
      axios
        .post('/api/auth/login', {
          email,
          password
        })
        .then((response) => {
          const {
            data: { token },
            message
          } = response.data;
          setSession(token);
          dispatch({
            type: 'LOGIN',
            payload: {
              user: jwtDecode(token)
            }
          });
          resolve(message);
        })
        .catch((error) => {
          reject(error);
        });
    });

  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/auth/register', {
      email,
      password,
      firstName,
      lastName
    });
    const data = await response.data;
    return data;
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const resetPassword = (email) =>
    new Promise((resolve, reject) => {
      axios
        .post('/api/auth/resetPassword', {
          email
        })
        .then((response) => {
          const { message } = response.data;
          resolve(message);
        })
        .catch((error) => {
          reject(error);
        });
    });

  const changePassword = (password, token, id) =>
    new Promise((resolve, reject) => {
      axios
        .post('/api/auth/changePassword', {
          password,
          token,
          id
        })
        .then((response) => {
          const { message } = response.data;
          resolve(message);
        })
        .catch((error) => {
          reject(error);
        });
    });

  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
        changePassword
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
