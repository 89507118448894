export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID;
export const apiEndPoint = process.env.REACT_APP_API_END_POINT;
export const cdn = process.env.REACT_APP_CDN_DOMAIN;
export const GOOGLE_API = process.env.REACT_APP_GOOGLE_API;
export const defaultSettings = {
  // light | dark
  themeMode: 'light',
  // ltr | rtl
  themeDirection: 'ltr',
  // bold | default
  themeContrast: 'default'
};

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER_MOBILE_HEIGHT = 64;
export const HEADER_DESKTOP_HEIGHT = 88;
export const DRAWER_WIDTH = 280;

// SOCIAL
export const SOCIAL_FACEBOOK = 'https://www.facebook.com/HireHoHire';
export const SOCIAL_GOOGLE = 'mailto:hireho.com@gmail.com';
export const SOCIAL_LINKEDIN = 'https://www.linkedin.com/company/hireho';
export const SOCIAL_TWITTER = '';
