// routes
import Router from 'routes';
// theme
import ThemeConfig from 'theme';
import GlobalStyles from 'theme/globalStyles';
// components
import ScrollToTop from 'components/ScrollToTop';
import GoogleAnalytics from 'components/GoogleAnalytics';
import NotistackProvider from 'components/NotistackProvider';
import ThemePrimaryColor from 'components/ThemePrimaryColor';
import { ProgressBarStyle } from 'components/LoadingScreen';

export default function App() {
  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <NotistackProvider>
          <GlobalStyles />
          <ProgressBarStyle />
          <ScrollToTop />
          <GoogleAnalytics />
          <Router />
        </NotistackProvider>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
