// routes
import { PATH_DASHBOARD } from 'routes/paths';
// components
import SvgIconStyle from 'components/SvgIconStyle';
import { Iconify } from 'components';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/static/icons/${name}.svg`} sx={{ width: '100%', height: '100%' }} />;

const ICONS = {
  home: <Iconify icon="solar:home-2-linear" sx={{ width: '100%', height: '100%' }} />,
  user: <Iconify icon="solar:users-group-rounded-linear" sx={{ width: '100%', height: '100%' }} />,
  education: <Iconify icon="solar:notebook-minimalistic-linear" sx={{ width: '100%', height: '100%' }} />,
  list: <Iconify icon="solar:list-linear" sx={{ width: '100%', height: '100%' }} />,
  building: <Iconify icon="solar:buildings-linear" sx={{ width: '100%', height: '100%' }} />,
  case: <Iconify icon="solar:case-linear" sx={{ width: '100%', height: '100%' }} />,
  mail: <Iconify icon="solar:mailbox-linear" sx={{ width: '100%', height: '100%' }} />,
  location: <Iconify icon="solar:map-point-linear" sx={{ width: '100%', height: '100%' }} />,
  translate: <Iconify icon="ic:baseline-g-translate" sx={{ width: '100%', height: '100%' }} />,
  level: <Iconify icon="solar:chart-linear" sx={{ width: '100%', height: '100%' }} />,
  lock: <Iconify icon="solar:shield-keyhole-linear" sx={{ width: '100%', height: '100%' }} />,
  widget: <Iconify icon="solar:widget-4-linear" sx={{ width: '100%', height: '100%' }} />,
  lightbulb: <Iconify icon="solar:lightbulb-minimalistic-linear" sx={{ width: '100%', height: '100%' }} />,
  time: <Iconify icon="solar:clock-circle-linear" sx={{ width: '100%', height: '100%' }} />
};

const sidebarConfig = [
  {
    subheader: '',
    items: [
      {
        title: 'Dashboard',
        icon: ICONS.home,
        path: PATH_DASHBOARD.home
      }
    ]
  },
  {
    subheader: 'MODULES',
    items: [
      {
        title: 'Users',
        path: PATH_DASHBOARD.candidates.root,
        icon: ICONS.user,
        children: [
          { title: 'List Users', path: PATH_DASHBOARD.candidates.listCandidates },
          { title: 'Add User', path: PATH_DASHBOARD.candidates.addCandidate }
        ]
      },
      {
        title: 'Education',
        path: PATH_DASHBOARD.education.root,
        icon: ICONS.education,
        children: [
          { title: 'List EducationTypes', path: PATH_DASHBOARD.education.listEducationTypes },
          { title: 'Add EducationType', path: PATH_DASHBOARD.education.addEducationType },
          { title: 'List Courses', path: PATH_DASHBOARD.education.listCourses },
          { title: 'Add Course', path: PATH_DASHBOARD.education.addCourse },
          { title: 'List Degrees', path: PATH_DASHBOARD.education.listDegrees },
          { title: 'Add Degree', path: PATH_DASHBOARD.education.addDegree },
          { title: 'List Institutes', path: PATH_DASHBOARD.education.listInstitutes },
          { title: 'Add Institute', path: PATH_DASHBOARD.education.addInstitute }
        ]
      },
      {
        title: 'Inquiry',
        path: PATH_DASHBOARD.inquiry.root,
        icon: ICONS.list,
        children: [
          { title: 'List', path: PATH_DASHBOARD.inquiry.listInquiries },
          { title: 'Add', path: PATH_DASHBOARD.inquiry.addInquiry }
        ]
      },
      {
        title: 'Employer',
        path: PATH_DASHBOARD.employer.root,
        icon: ICONS.user,
        children: [
          { title: 'List', path: PATH_DASHBOARD.employer.listEmployers },
          { title: 'Add', path: PATH_DASHBOARD.employer.addEmployer }
        ]
      },
      {
        title: 'Company',
        path: PATH_DASHBOARD.company.root,
        icon: ICONS.building,
        children: [
          { title: 'List', path: PATH_DASHBOARD.company.listCompanies },
          { title: 'Add', path: PATH_DASHBOARD.company.addCompany }
        ]
      },
      {
        title: 'Jobs',
        path: PATH_DASHBOARD.job.root,
        icon: ICONS.case,
        children: [
          { title: 'List', path: PATH_DASHBOARD.job.listJobs },
          { title: 'Add', path: PATH_DASHBOARD.job.addJob }
        ]
      },
      {
        title: 'Mailer Pending',
        path: PATH_DASHBOARD.mailer.root,
        icon: ICONS.mail,
        children: [
          { title: 'Send Email', path: PATH_DASHBOARD.education.listEducationTypes },
          { title: 'List Templates', path: PATH_DASHBOARD.education.addEducationType },
          { title: 'Add Template', path: PATH_DASHBOARD.education.listCourses },
          { title: 'List Subscriber', path: PATH_DASHBOARD.education.addCourse },
          { title: 'Add Subscriber', path: PATH_DASHBOARD.education.listDegrees }
        ]
      }
    ]
  },
  {
    subheader: 'TRANSLATION',
    items: [
      {
        title: 'Languages',
        icon: ICONS.translate,
        path: PATH_DASHBOARD.language.root,
        children: [
          { title: 'List Languages', path: PATH_DASHBOARD.language.listLanguages },
          { title: 'Add Language', path: PATH_DASHBOARD.language.addLanguage }
        ]
      },
      {
        title: 'Language Levels',
        icon: ICONS.level,
        path: PATH_DASHBOARD.languageLevel.root,
        children: [
          { title: 'List Language Levels', path: PATH_DASHBOARD.languageLevel.listLanguageLevels },
          { title: 'Add Language Level', path: PATH_DASHBOARD.languageLevel.addLanguageLevel }
        ]
      }
    ]
  },
  {
    subheader: 'MANAGE LOCATION',
    items: [
      {
        title: 'Countries',
        icon: ICONS.location,
        path: PATH_DASHBOARD.country.root,
        children: [
          { title: 'List Countries', path: PATH_DASHBOARD.country.listCountries },
          { title: 'Add Country', path: PATH_DASHBOARD.country.addCountry }
        ]
      },
      {
        title: 'States',
        icon: ICONS.location,
        path: PATH_DASHBOARD.state.root,
        children: [
          { title: 'List States', path: PATH_DASHBOARD.state.listStates },
          { title: 'Add State', path: PATH_DASHBOARD.state.addState }
        ]
      },
      {
        title: 'City',
        icon: ICONS.location,
        path: PATH_DASHBOARD.city.root,
        children: [
          { title: 'List Cities', path: PATH_DASHBOARD.city.listCities },
          { title: 'Add City', path: PATH_DASHBOARD.city.addCity }
        ]
      }
    ]
  },
  {
    subheader: 'ATTRIBUTES',
    items: [
      {
        title: 'Career Levels',
        icon: ICONS.level,
        path: PATH_DASHBOARD.attributes.careerLevel.root,
        children: [
          { title: 'List Career Levels', path: PATH_DASHBOARD.attributes.careerLevel.listCareerLevels },
          { title: 'Add Career Level', path: PATH_DASHBOARD.attributes.careerLevel.addCareerLevel }
        ]
      },
      {
        title: 'Departments',
        icon: ICONS.case,
        path: PATH_DASHBOARD.attributes.department.root,
        children: [
          { title: 'List Departments', path: PATH_DASHBOARD.attributes.department.listDepartments },
          { title: 'Add Department', path: PATH_DASHBOARD.attributes.department.addDepartment }
        ]
      },
      {
        title: 'Industries',
        icon: ICONS.building,
        path: PATH_DASHBOARD.attributes.industry.root,
        children: [
          { title: 'List Industry Types', path: PATH_DASHBOARD.attributes.industry.listIndustryTypes },
          { title: 'Add Industry Type', path: PATH_DASHBOARD.attributes.industry.addIndustryType },
          { title: 'List Industries', path: PATH_DASHBOARD.attributes.industry.listIndustries },
          { title: 'Add Industry', path: PATH_DASHBOARD.attributes.industry.addIndustry }
        ]
      },
      {
        title: 'Job Role Type',
        icon: ICONS.widget,
        path: PATH_DASHBOARD.attributes.role.root,
        children: [
          { title: 'List Role Types', path: PATH_DASHBOARD.attributes.role.listRoleTypes },
          { title: 'Add Role Type', path: PATH_DASHBOARD.attributes.role.addRoleType },
          { title: 'List Role', path: PATH_DASHBOARD.attributes.role.listRoles },
          { title: 'Add Role', path: PATH_DASHBOARD.attributes.role.addRole }
        ]
      },
      {
        title: 'Employment Type',
        icon: ICONS.widget,
        path: PATH_DASHBOARD.attributes.employmentType.root,
        children: [
          { title: 'List Employment Types', path: PATH_DASHBOARD.attributes.employmentType.listEmploymentTypes },
          { title: 'Add Employment Type', path: PATH_DASHBOARD.attributes.employmentType.addEmploymentTypes }
        ]
      },
      {
        title: 'Work mode',
        icon: ICONS.widget,
        path: PATH_DASHBOARD.attributes.workMode.root,
        children: [
          { title: 'List Work Modes', path: PATH_DASHBOARD.attributes.workMode.listWorkModes },
          { title: 'Add Work Mode', path: PATH_DASHBOARD.attributes.workMode.addWorkMode }
        ]
      },
      {
        title: 'Skills',
        icon: ICONS.lightbulb,
        path: PATH_DASHBOARD.attributes.skill.root,
        children: [
          { title: 'List Job Skills', path: PATH_DASHBOARD.attributes.skill.listSkills },
          { title: 'Add Job Skill', path: PATH_DASHBOARD.attributes.skill.addSkill }
        ]
      },
      {
        title: 'Job Shifts',
        icon: ICONS.time,
        path: PATH_DASHBOARD.attributes.shift.root,
        children: [
          { title: 'List Job Shifts', path: PATH_DASHBOARD.attributes.shift.listShifts },
          { title: 'Add Job Shifts', path: PATH_DASHBOARD.attributes.shift.addShift }
        ]
      },
      {
        title: 'Ownership Types',
        icon: ICONS.widget,
        path: PATH_DASHBOARD.attributes.ownership.root,
        children: [
          { title: 'List Ownership Types', path: PATH_DASHBOARD.attributes.ownership.listOwnerships },
          { title: 'Add Ownership Type', path: PATH_DASHBOARD.attributes.ownership.addOwnership }
        ]
      }
    ]
  }
];

export default sidebarConfig;
