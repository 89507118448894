import PropTypes from 'prop-types';
// material
import { alpha, ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
//
import palette from '../theme/palette';
import componentsOverride from '../theme/overrides';

// ----------------------------------------------------------------------

ThemePrimaryColor.propTypes = {
  children: PropTypes.node
};

export default function ThemePrimaryColor({ children }) {
  const defaultTheme = useTheme();
  const setColor = {
    name: 'default',
    ...palette.light.primary
  };
  const themeOptions = {
    ...defaultTheme,
    palette: {
      ...defaultTheme.palette,
      primary: setColor
    },
    customShadows: {
      ...defaultTheme.customShadows,
      primary: `0 8px 16px 0 ${alpha(setColor.main, 0.24)}`
    }
  };

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
