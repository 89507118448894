const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
  changePassword: path(ROOTS_AUTH, '/change-password')
};

// ----------------------------------------------------------------------

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  login: '/auth/login',
  register: '/auth/register',
  page404: '/404',
  page500: '/500'
};

// ----------------------------------------------------------------------

export const PATH_DASHBOARD = {
  root: path(ROOTS_DASHBOARD, '/'),
  home: path(ROOTS_DASHBOARD, '/home'),
  employers: path(ROOTS_DASHBOARD, '/employers'),
  category: path(ROOTS_DASHBOARD, '/category'),
  inquiry: {
    root: path(ROOTS_DASHBOARD, '/inquiry'),
    listInquiries: path(ROOTS_DASHBOARD, '/inquiry/list'),
    addInquiry: path(ROOTS_DASHBOARD, '/inquiry/add'),
    editInquiry: path(ROOTS_DASHBOARD, '/inquiry/edit')
  },
  employer: {
    root: path(ROOTS_DASHBOARD, '/employer'),
    listEmployers: path(ROOTS_DASHBOARD, '/employer/list'),
    addEmployer: path(ROOTS_DASHBOARD, '/employer/add'),
    editEmployer: path(ROOTS_DASHBOARD, '/employer/edit')
  },
  company: {
    root: path(ROOTS_DASHBOARD, '/company'),
    listCompanies: path(ROOTS_DASHBOARD, '/company/list'),
    addCompany: path(ROOTS_DASHBOARD, '/company/add'),
    editCompany: path(ROOTS_DASHBOARD, '/company/edit')
  },
  job: {
    root: path(ROOTS_DASHBOARD, '/job'),
    listJobs: path(ROOTS_DASHBOARD, '/job/list'),
    addJob: path(ROOTS_DASHBOARD, '/job/add'),
    editJob: path(ROOTS_DASHBOARD, '/job/edit')
  },
  mailer: {
    root: path(ROOTS_DASHBOARD, '/mailer'),
    sendEmail: path(ROOTS_DASHBOARD, '/mailer/send-email'),
    listEmailTemplates: path(ROOTS_DASHBOARD, '/mailer/list-email-templates'),
    addEmailTemplates: path(ROOTS_DASHBOARD, '/mailer/add-email-template'),
    listNewsLetterSubscriber: path(ROOTS_DASHBOARD, '/mailer/list-newsletter-subscriber'),
    addNewsLetterSubscriber: path(ROOTS_DASHBOARD, '/mailer/add-newsletter-subscriber')
  },
  candidates: {
    root: path(ROOTS_DASHBOARD, '/candidates'),
    listCandidates: path(ROOTS_DASHBOARD, '/candidates/list-candidates'),
    addCandidate: path(ROOTS_DASHBOARD, '/candidates/add-candidate'),
    editCandidate: path(ROOTS_DASHBOARD, '/candidates/edit-candidate')
  },
  education: {
    root: path(ROOTS_DASHBOARD, '/education'),
    listEducationTypes: path(ROOTS_DASHBOARD, '/education/list-educationTypes'),
    addEducationType: path(ROOTS_DASHBOARD, '/education/add-educationType'),
    editEducationType: path(ROOTS_DASHBOARD, '/education/edit-educationType'),
    listCourses: path(ROOTS_DASHBOARD, '/education/list-courses'),
    addCourse: path(ROOTS_DASHBOARD, '/education/add-course'),
    editCourse: path(ROOTS_DASHBOARD, '/education/edit-course'),
    listDegrees: path(ROOTS_DASHBOARD, '/education/list-degrees'),
    addDegree: path(ROOTS_DASHBOARD, '/education/add-degree'),
    editDegree: path(ROOTS_DASHBOARD, '/education/edit-degree'),
    listInstitutes: path(ROOTS_DASHBOARD, '/education/list-institutes'),
    addInstitute: path(ROOTS_DASHBOARD, '/education/add-institute'),
    editInstitute: path(ROOTS_DASHBOARD, '/education/edit-institute')
  },
  country: {
    root: path(ROOTS_DASHBOARD, '/country'),
    listCountries: path(ROOTS_DASHBOARD, '/country/list-countries'),
    addCountry: path(ROOTS_DASHBOARD, '/country/add-country'),
    editCountry: path(ROOTS_DASHBOARD, '/country/edit-country')
  },
  state: {
    root: path(ROOTS_DASHBOARD, '/state'),
    listStates: path(ROOTS_DASHBOARD, '/state/list-states'),
    addState: path(ROOTS_DASHBOARD, '/state/add-state'),
    editState: path(ROOTS_DASHBOARD, '/state/edit-state/')
  },
  city: {
    root: path(ROOTS_DASHBOARD, '/city'),
    listCities: path(ROOTS_DASHBOARD, '/city/list-cities'),
    addCity: path(ROOTS_DASHBOARD, '/city/add-city'),
    editCity: path(ROOTS_DASHBOARD, '/city/edit-city/')
  },
  language: {
    root: path(ROOTS_DASHBOARD, '/language'),
    listLanguages: path(ROOTS_DASHBOARD, '/language/list-languages'),
    addLanguage: path(ROOTS_DASHBOARD, '/language/add-language'),
    editLanguage: path(ROOTS_DASHBOARD, '/language/edit-language')
  },
  languageLevel: {
    root: path(ROOTS_DASHBOARD, '/lang-level'),
    listLanguageLevels: path(ROOTS_DASHBOARD, '/lang-level/list-languageLevels'),
    addLanguageLevel: path(ROOTS_DASHBOARD, '/lang-level/add-languageLevel'),
    editLanguageLevel: path(ROOTS_DASHBOARD, '/lang-level/edit-languageLevel')
  },
  attributes: {
    root: path(ROOTS_DASHBOARD, '/attributes'),
    careerLevel: {
      root: path(ROOTS_DASHBOARD, '/attributes/career-level'),
      listCareerLevels: path(ROOTS_DASHBOARD, '/attributes/career-level/list'),
      addCareerLevel: path(ROOTS_DASHBOARD, '/attributes/career-level/add'),
      editCareerLevel: path(ROOTS_DASHBOARD, '/attributes/career-level/edit')
    },
    department: {
      root: path(ROOTS_DASHBOARD, '/attributes/department'),
      listDepartments: path(ROOTS_DASHBOARD, '/attributes/department/list'),
      addDepartment: path(ROOTS_DASHBOARD, '/attributes/department/add'),
      editDepartment: path(ROOTS_DASHBOARD, '/attributes/department/edit')
    },
    industry: {
      root: path(ROOTS_DASHBOARD, '/attributes/industry'),
      listIndustryTypes: path(ROOTS_DASHBOARD, '/attributes/industry/list-types'),
      addIndustryType: path(ROOTS_DASHBOARD, '/attributes/industry/add-type'),
      editIndustryType: path(ROOTS_DASHBOARD, '/attributes/industry/edit-type'),
      listIndustries: path(ROOTS_DASHBOARD, '/attributes/industry/list'),
      addIndustry: path(ROOTS_DASHBOARD, '/attributes/industry/add'),
      editIndustry: path(ROOTS_DASHBOARD, '/attributes/industry/edit')
    },
    role: {
      root: path(ROOTS_DASHBOARD, '/attributes/role'),
      listRoleTypes: path(ROOTS_DASHBOARD, '/attributes/role/list-types'),
      addRoleType: path(ROOTS_DASHBOARD, '/attributes/role/add-type'),
      editRoleType: path(ROOTS_DASHBOARD, '/attributes/role/edit-type'),
      listRoles: path(ROOTS_DASHBOARD, '/attributes/role/list'),
      addRole: path(ROOTS_DASHBOARD, '/attributes/role/add'),
      editRole: path(ROOTS_DASHBOARD, '/attributes/role/edit')
    },
    employmentType: {
      root: path(ROOTS_DASHBOARD, '/attributes/employment-type'),
      listEmploymentTypes: path(ROOTS_DASHBOARD, '/attributes/employment-type/list'),
      addEmploymentTypes: path(ROOTS_DASHBOARD, '/attributes/employment-type/add'),
      editEmploymentTypes: path(ROOTS_DASHBOARD, '/attributes/employment-type/edit')
    },
    workMode: {
      root: path(ROOTS_DASHBOARD, '/attributes/work-mode'),
      listWorkModes: path(ROOTS_DASHBOARD, '/attributes/work-mode/list'),
      addWorkMode: path(ROOTS_DASHBOARD, '/attributes/work-mode/add'),
      editWorkMode: path(ROOTS_DASHBOARD, '/attributes/work-mode/edit')
    },
    skill: {
      root: path(ROOTS_DASHBOARD, '/attributes/skill'),
      listSkills: path(ROOTS_DASHBOARD, '/attributes/skill/list'),
      addSkill: path(ROOTS_DASHBOARD, '/attributes/skill/add'),
      editSkill: path(ROOTS_DASHBOARD, '/attributes/skill/edit')
    },
    shift: {
      root: path(ROOTS_DASHBOARD, '/attributes/shift'),
      listShifts: path(ROOTS_DASHBOARD, '/attributes/shift/list'),
      addShift: path(ROOTS_DASHBOARD, '/attributes/shift/add'),
      editShift: path(ROOTS_DASHBOARD, '/attributes/shift/edit')
    },
    ownership: {
      root: path(ROOTS_DASHBOARD, '/attributes/ownership'),
      listOwnerships: path(ROOTS_DASHBOARD, '/attributes/ownership/list'),
      addOwnership: path(ROOTS_DASHBOARD, '/attributes/ownership/add'),
      editOwnership: path(ROOTS_DASHBOARD, '/attributes/ownership/edit')
    }
    //
    // listJobSkills: path(ROOTS_DASHBOARD, '/attributes/list-job-skills'),
    // addJobSkill: path(ROOTS_DASHBOARD, '/attributes/add-job-skill'),
    // editJobSkill: path(ROOTS_DASHBOARD, '/attributes/edit-job-skill'),
    // //
    // listJobTypes: path(ROOTS_DASHBOARD, '/attributes/list-job-types'),
    // addJobType: path(ROOTS_DASHBOARD, '/attributes/add-job-type'),
    // editJobType: path(ROOTS_DASHBOARD, '/attributes/edit-job-type'),
    // //
    // listJobShifts: path(ROOTS_DASHBOARD, '/attributes/list-job-shifts'),
    // addJobShift: path(ROOTS_DASHBOARD, '/attributes/add-job-shift'),
    // editJobShift: path(ROOTS_DASHBOARD, '/attributes/edit-job-shift'),
    // //
    // listDegreeLevels: path(ROOTS_DASHBOARD, '/attributes/list-degree-levels'),
    // addDegreeLevel: path(ROOTS_DASHBOARD, '/attributes/add-degree-level'),
    // editDegreeLevel: path(ROOTS_DASHBOARD, '/attributes/edit-degree-level'),
    // //
    // listDegreeTypes: path(ROOTS_DASHBOARD, '/attributes/list-degree-types'),
    // addDegreeType: path(ROOTS_DASHBOARD, '/attributes/add-degree-type'),
    // editDegreeType: path(ROOTS_DASHBOARD, '/attributes/edit-degree-type'),
    // //
    // listMajorSubjects: path(ROOTS_DASHBOARD, '/attributes/list-major-subjects'),
    // addMajorSubject: path(ROOTS_DASHBOARD, '/attributes/add-major-subject'),
    // editMajorSubject: path(ROOTS_DASHBOARD, '/attributes/edit-major-subject'),
    // //
    // listResultTypes: path(ROOTS_DASHBOARD, '/attributes/list-result-types'),
    // addResultType: path(ROOTS_DASHBOARD, '/attributes/add-result-type'),
    // editResultType: path(ROOTS_DASHBOARD, '/attributes/edit-result-type'),
    // //
    // listOwnershipTypes: path(ROOTS_DASHBOARD, '/attributes/list-ownership-types'),
    // addOwnershipType: path(ROOTS_DASHBOARD, '/attributes/add-ownership-type'),
    // editOwnershipType: path(ROOTS_DASHBOARD, '/attributes/edit-ownership-type'),
    // //
    // listSalaryPeriods: path(ROOTS_DASHBOARD, '/attributes/list-salary-periods'),
    // addSalaryPeriod: path(ROOTS_DASHBOARD, '/attributes/add-salary-period'),
    // editSalaryPeriod: path(ROOTS_DASHBOARD, '/attributes/edit-salary-period')
  }
};
