import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from 'layouts/dashboard';
import LogoOnlyLayout from 'layouts/LogoOnlyLayout';
// guards
import GuestGuard from 'guards/GuestGuard';
import AuthGuard from 'guards/AuthGuard';

// components
import LoadingScreen from 'components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');
  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        {
          path: 'reset-password',
          element: (
            <GuestGuard>
              <ResetPassword />
            </GuestGuard>
          )
        },
        {
          path: 'verify',
          element: (
            <GuestGuard>
              <VerifyCode />
            </GuestGuard>
          )
        },
        {
          path: 'change-password/:id/:token',
          element: (
            <GuestGuard>
              <ChangePassword />
            </GuestGuard>
          )
        }
      ]
    },
    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/home" replace /> },
        { path: 'home', element: <Home /> },
        {
          path: 'candidates',
          children: [
            { element: <Navigate to="/dashboard/candidates/list-candidates" replace /> },
            { path: 'list-candidates', element: <Candidate /> },
            { path: 'add-candidate', element: <AddCandidate /> },
            { path: 'edit-candidate', element: <EditCandidate /> }
          ]
        },
        {
          path: 'education',
          children: [
            { element: <Navigate to="/dashboard/education/list-educationTypes" replace /> },
            { path: 'list-educationTypes', element: <ListEducationTypes /> },
            { path: 'add-educationType', element: <AddEducationType /> },
            { path: 'edit-educationType', element: <EditEducationType /> },
            { path: 'list-courses', element: <ListCourses /> },
            { path: 'add-course', element: <AddCourse /> },
            { path: 'edit-course', element: <EditCourse /> },
            { path: 'list-degrees', element: <ListDegrees /> },
            { path: 'add-degree', element: <AddDegree /> },
            { path: 'edit-degree', element: <EditDegree /> },
            { path: 'list-institutes', element: <ListInstitutes /> },
            { path: 'add-institute', element: <AddInstitute /> },
            { path: 'edit-institute', element: <EditInstitute /> }
          ]
        },
        {
          path: 'inquiry',
          children: [
            { element: <Navigate to="/dashboard/inquiry/list" replace /> },
            { path: 'list', element: <ListInquiries /> },
            { path: 'add', element: <AddInquiry /> },
            { path: 'edit', element: <EditInquiry /> }
          ]
        },
        {
          path: 'employer',
          children: [
            { element: <Navigate to="/dashboard/employer/list" replace /> },
            { path: 'list', element: <ListEmployers /> },
            { path: 'add', element: <AddEmployer /> },
            { path: 'edit', element: <EditEmployer /> }
          ]
        },
        {
          path: 'company',
          children: [
            { element: <Navigate to="/dashboard/company/list" replace /> },
            { path: 'list', element: <ListCompany /> },
            { path: 'add', element: <AddCompany /> },
            { path: 'edit', element: <EditCompany /> }
          ]
        },
        {
          path: 'job',
          children: [
            { element: <Navigate to="/dashboard/job/list" replace /> },
            { path: ':id', element: <ViewJob /> },
            { path: 'list', element: <ListJob /> },
            { path: 'add', element: <AddJob /> },
            { path: 'edit', element: <EditJob /> }
          ]
        },
        {
          path: 'language',
          children: [
            { element: <Navigate to="/dashboard/language/list-languages" replace /> },
            { path: 'list-languages', element: <ListLanguage /> },
            { path: 'add-language', element: <AddLanguage /> },
            { path: 'edit-language', element: <EditLanguage /> }
          ]
        },
        {
          path: 'lang-level',
          children: [
            { element: <Navigate to="/dashboard/lang-level/list-languageLevels" replace /> },
            { path: 'list-languageLevels', element: <ListLanguageLevels /> },
            { path: 'add-languageLevel', element: <AddLanguageLevel /> },
            { path: 'edit-languageLevel', element: <EditLanguageLevel /> }
          ]
        },
        {
          path: 'country',
          children: [
            { element: <Navigate to="/dashboard/country/list-countries" replace /> },
            { path: 'list-countries', element: <ListCountries /> },
            { path: 'add-country', element: <AddCountry /> },
            { path: 'edit-country', element: <EditCountry /> }
          ]
        },
        {
          path: 'state',
          children: [
            { element: <Navigate to="/dashboard/state/list-states" replace /> },
            { path: 'list-states', element: <ListStates /> },
            { path: 'add-state', element: <AddState /> },
            { path: 'edit-state', element: <EditState /> }
          ]
        },
        {
          path: 'city',
          children: [
            { element: <Navigate to="/dashboard/city/list-cities" replace /> },
            { path: 'list-cities', element: <ListCities /> },
            { path: 'add-city', element: <AddCity /> },
            { path: 'edit-city', element: <EditCity /> }
          ]
        },
        {
          path: 'attributes',
          children: [
            { element: <Navigate to="/dashboard/attributes/career-level/list" replace /> },
            {
              path: 'career-level',
              children: [
                { element: <Navigate to="/dashboard/attributes/career-level/list" replace /> },
                { path: 'list', element: <ListCareerLevel /> },
                { path: 'add', element: <AddCareerLevel /> },
                { path: 'edit', element: <EditCareerLevel /> }
              ]
            },
            {
              path: 'department',
              children: [
                { element: <Navigate to="/dashboard/attributes/department/list" replace /> },
                { path: 'list', element: <ListDepartment /> },
                { path: 'add', element: <AddDepartment /> },
                { path: 'edit', element: <EditDepartment /> }
              ]
            },
            {
              path: 'industry',
              children: [
                { element: <Navigate to="/dashboard/attributes/industry/list-types" replace /> },
                { path: 'list-types', element: <ListIndustryTypes /> },
                { path: 'add-type', element: <AddIndustryType /> },
                { path: 'edit-type', element: <EditIndustryType /> },
                { path: 'list', element: <ListIndustries /> },
                { path: 'add', element: <AddIndustry /> },
                { path: 'edit', element: <EditIndustry /> }
              ]
            },
            {
              path: 'role',
              children: [
                { element: <Navigate to="/dashboard/attributes/role/list-types" replace /> },
                { path: 'list-types', element: <ListRoleTypes /> },
                { path: 'add-type', element: <AddRoleType /> },
                { path: 'edit-type', element: <EditRoleType /> },
                { path: 'list', element: <ListRoles /> },
                { path: 'add', element: <AddRole /> },
                { path: 'edit', element: <EditRole /> }
              ]
            },
            {
              path: 'employment-type',
              children: [
                { element: <Navigate to="/dashboard/attributes/employment-type/list" replace /> },
                { path: 'list', element: <ListEmploymentTypes /> },
                { path: 'add', element: <AddEmploymentType /> },
                { path: 'edit', element: <EditEmploymentType /> }
              ]
            },
            {
              path: 'work-mode',
              children: [
                { element: <Navigate to="/dashboard/attributes/work-mode/list" replace /> },
                { path: 'list', element: <ListWorkModes /> },
                { path: 'add', element: <AddWorkMode /> },
                { path: 'edit', element: <EditWorkMode /> }
              ]
            },
            {
              path: 'skill',
              children: [
                { element: <Navigate to="/dashboard/attributes/skill/list" replace /> },
                { path: 'list', element: <ListSkills /> },
                { path: 'add', element: <AddSkill /> },
                { path: 'edit', element: <EditSkill /> }
              ]
            },
            {
              path: 'shift',
              children: [
                { element: <Navigate to="/dashboard/attributes/shift/list" replace /> },
                { path: 'list', element: <ListShifts /> },
                { path: 'add', element: <AddShift /> },
                { path: 'edit', element: <EditShift /> }
              ]
            },
            {
              path: 'ownership',
              children: [
                { element: <Navigate to="/dashboard/attributes/ownership/list" replace /> },
                { path: 'list', element: <ListOwnerships /> },
                { path: 'add', element: <AddOwnership /> },
                { path: 'edit', element: <EditOwnership /> }
              ]
            }
          ]
        }
        // { path: 'location', element: <Location /> },
        // { path: 'category', element: <Category /> },
        // { path: 'company', element: <Company /> }
      ]
    },
    {
      path: '/',
      element: <Navigate to="/dashboard/home" replace />
    },
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS
// Authentication
const Login = Loadable(lazy(() => import('pages/authentication/Login')));
const Register = Loadable(lazy(() => import('pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('pages/authentication/VerifyCode')));
const ChangePassword = Loadable(lazy(() => import('pages/authentication/ChangePassword')));
//
const Home = Loadable(lazy(() => import('pages/Dashboard')));
const Candidate = Loadable(lazy(() => import('pages/user/List')));
const AddCandidate = Loadable(lazy(() => import('pages/user/Add')));
const EditCandidate = Loadable(lazy(() => import('pages/user/Edit')));
const ListEducationTypes = Loadable(lazy(() => import('pages/education/educationType/List')));
const AddEducationType = Loadable(lazy(() => import('pages/education/educationType/Add')));
const EditEducationType = Loadable(lazy(() => import('pages/education/educationType/Edit')));
const ListCourses = Loadable(lazy(() => import('pages/education/course/List')));
const AddCourse = Loadable(lazy(() => import('pages/education/course/Add')));
const EditCourse = Loadable(lazy(() => import('pages/education/course/Edit')));
const ListDegrees = Loadable(lazy(() => import('pages/education/degree/List')));
const AddDegree = Loadable(lazy(() => import('pages/education/degree/Add')));
const EditDegree = Loadable(lazy(() => import('pages/education/degree/Edit')));
const ListInstitutes = Loadable(lazy(() => import('pages/education/institute/List')));
const AddInstitute = Loadable(lazy(() => import('pages/education/institute/Add')));
const EditInstitute = Loadable(lazy(() => import('pages/education/institute/Edit')));
const ListCountries = Loadable(lazy(() => import('pages/location/country/List')));
const AddCountry = Loadable(lazy(() => import('pages/location/country/Add')));
const EditCountry = Loadable(lazy(() => import('pages/location/country/Edit')));
const ListStates = Loadable(lazy(() => import('pages/location/state/List')));
const AddState = Loadable(lazy(() => import('pages/location/state/Add')));
const EditState = Loadable(lazy(() => import('pages/location/state/Edit')));
const ListCities = Loadable(lazy(() => import('pages/location/city/List')));
const AddCity = Loadable(lazy(() => import('pages/location/city/Add')));
const EditCity = Loadable(lazy(() => import('pages/location/city/Edit')));
const ListLanguage = Loadable(lazy(() => import('pages/language/List')));
const AddLanguage = Loadable(lazy(() => import('pages/language/Add')));
const EditLanguage = Loadable(lazy(() => import('pages/language/Edit')));
const ListIndustryTypes = Loadable(lazy(() => import('pages/industryType/List')));
const AddIndustryType = Loadable(lazy(() => import('pages/industryType/Add')));
const EditIndustryType = Loadable(lazy(() => import('pages/industryType/Edit')));
const ListIndustries = Loadable(lazy(() => import('pages/industry/List')));
const AddIndustry = Loadable(lazy(() => import('pages/industry/Add')));
const EditIndustry = Loadable(lazy(() => import('pages/industry/Edit')));
const ListLanguageLevels = Loadable(lazy(() => import('pages/languageLevels/List')));
const AddLanguageLevel = Loadable(lazy(() => import('pages/languageLevels/Add')));
const EditLanguageLevel = Loadable(lazy(() => import('pages/languageLevels/Edit')));
const ListCareerLevel = Loadable(lazy(() => import('pages/careerLevel/List')));
const AddCareerLevel = Loadable(lazy(() => import('pages/careerLevel/Add')));
const EditCareerLevel = Loadable(lazy(() => import('pages/careerLevel/Edit')));
const ListDepartment = Loadable(lazy(() => import('pages/department/List')));
const AddDepartment = Loadable(lazy(() => import('pages/department/Add')));
const EditDepartment = Loadable(lazy(() => import('pages/department/Edit')));
const ListRoleTypes = Loadable(lazy(() => import('pages/roleType/List')));
const AddRoleType = Loadable(lazy(() => import('pages/roleType/Add')));
const EditRoleType = Loadable(lazy(() => import('pages/roleType/Edit')));
const ListRoles = Loadable(lazy(() => import('pages/role/List')));
const AddRole = Loadable(lazy(() => import('pages/role/Add')));
const EditRole = Loadable(lazy(() => import('pages/role/Edit')));
const ListEmploymentTypes = Loadable(lazy(() => import('pages/employmentType/List')));
const AddEmploymentType = Loadable(lazy(() => import('pages/employmentType/Add')));
const EditEmploymentType = Loadable(lazy(() => import('pages/employmentType/Edit')));
const ListWorkModes = Loadable(lazy(() => import('pages/workMode/List')));
const AddWorkMode = Loadable(lazy(() => import('pages/workMode/Add')));
const EditWorkMode = Loadable(lazy(() => import('pages/workMode/Edit')));
const ListSkills = Loadable(lazy(() => import('pages/skill/List')));
const AddSkill = Loadable(lazy(() => import('pages/skill/Add')));
const EditSkill = Loadable(lazy(() => import('pages/skill/Edit')));
const ListShifts = Loadable(lazy(() => import('pages/shift/List')));
const AddShift = Loadable(lazy(() => import('pages/shift/Add')));
const EditShift = Loadable(lazy(() => import('pages/shift/Edit')));
const ListOwnerships = Loadable(lazy(() => import('pages/ownership/List')));
const AddOwnership = Loadable(lazy(() => import('pages/ownership/Add')));
const EditOwnership = Loadable(lazy(() => import('pages/ownership/Edit')));
const ListInquiries = Loadable(lazy(() => import('pages/inquiry/List')));
const AddInquiry = Loadable(lazy(() => import('pages/inquiry/Add')));
const EditInquiry = Loadable(lazy(() => import('pages/inquiry/Edit')));
const ListEmployers = Loadable(lazy(() => import('pages/employer/List')));
const AddEmployer = Loadable(lazy(() => import('pages/employer/Add')));
const EditEmployer = Loadable(lazy(() => import('pages/employer/Edit')));
const ListCompany = Loadable(lazy(() => import('pages/company/List')));
const AddCompany = Loadable(lazy(() => import('pages/company/Add')));
const EditCompany = Loadable(lazy(() => import('pages/company/Edit')));
const ListJob = Loadable(lazy(() => import('pages/job/List')));
const AddJob = Loadable(lazy(() => import('pages/job/Add')));
const EditJob = Loadable(lazy(() => import('pages/job/Edit')));
const ViewJob = Loadable(lazy(() => import('pages/job/Job')));
//
const ComingSoon = Loadable(lazy(() => import('pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('pages/Maintenance')));
const NotFound = Loadable(lazy(() => import('pages/Page404')));
const Page500 = Loadable(lazy(() => import('pages/Page500')));
