import axios from 'axios';
import { apiEndPoint } from 'config';

// Store requests
const sourceRequest = {};
// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: `${apiEndPoint}`
});

axiosInstance.interceptors.request.use(
  (request) => {
    if (sourceRequest[request.url]) {
      sourceRequest[request.url].cancel();
    }
    const axiosSource = axios.CancelToken.source();
    request.cancelToken = axiosSource.token;
    sourceRequest[request.url] = { cancel: axiosSource.cancel };
    return request;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => {
    if (sourceRequest[response.config.url]) {
      delete sourceRequest[response.config.url];
    }
    return response;
  },
  (error) => {
    if (sourceRequest[error.response.config.url]) {
      delete sourceRequest[error.response.config.url];
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export default axiosInstance;
